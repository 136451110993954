const images = {
  logoVip1: require('~/assets/images/logo-vip1.png'),
  logoVip2: require('~/assets/images/logo-vip2.png'),
  avatarDefault: require('~/assets/images/logo-square.png'),
  lg1: require('~/assets/images/lg1.png'),
  lg2: require('~/assets/images/lg2.png'),
  lg3: require('~/assets/images/lg3.png'),
  lg4: require('~/assets/images/lg4.png'),
  appStore: require('~/assets/images/app-store.svg').default,
  googlePlay: require('~/assets/images/google-play.svg').default,
  cart: require('~/assets/images/cart.svg').default,
  notFound: require('~/assets/images/error_images/404.png'),
  forbidden: require('~/assets/images/error_images/403.png'),
  internalServer: require('~/assets/images/error_images/500.png'),
  rocket: require('~/assets/images/error_images/rocket.svg').default,
  earth: require('~/assets/images/error_images/earth.svg').default,
  moon: require('~/assets/images/error_images/moon.png'),
  aboutOffice: require('~/assets/images/about/van-phong.jpg'),
  simple: require('~/assets/images/about/simple.png'),
  happy: require('~/assets/images/about/happy.png'),
  together: require('~/assets/images/about/together.png'),
  trip1: require('~/assets/images/about/trip1.png'),
  trip2: require('~/assets/images/about/trip2.jpg'),
  devBg1: require('~/assets/images/about/developer-team.svg').default,
  devBg2: require('~/assets/images/about/bg2.svg').default,
  developer1: require('~/assets/images/about/avatar1.jpg'),
  developer2: require('~/assets/images/about/avatar2.jpg'),
  developer3: require('~/assets/images/about/avatar3.png'),
  developer4: require('~/assets/images/about/avatar4.jpg'),
  developer5: require('~/assets/images/about/avatar5.jpg'),
  vi: require('~/assets/images/languages/vi.png'),
  en: require('~/assets/images/languages/en.png'),
  zh: require('~/assets/images/languages/zh.png'),
  bank: require('~/assets/images/checkout/bank.png'),
  cash: require('~/assets/images/checkout/cash.png'),
  defaultImg: require('~/assets/images/default-img.png'),
  emptyOrder: require('~/assets/images/empty-order.png'),
  qrPay: require('~/assets/images/checkout/qr-payment.png'),
  banhtieusr: require('~/assets/images/products/bachtieusaurieng.jpg'),
  chickenBowl: require('~/assets/images/chicken-bowl.svg').default,
  logoTpBank: require('~/assets/images/vpBankLogo.png'),
};

export default images;
